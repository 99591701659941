<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Profile Edit</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters align="center" class="image_upload">
              <v-col cols="12" sm="2">
                <div class="symbol symbol-100 p-3 mr-5">
                  <img
                    id="image_id"
                    class="symbol-label"
                    :src="currentUserPersonalInfo.photo"
                    alt=""
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-file-input
                  outlined
                  dense
                  show-size
                  class="image_upload_box"
                  @change="onFileChange"
                  accept="image/*"
                  label="Upload Photo"
                  v-model="employee.avatar"
                >
                </v-file-input>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="employee.first_name"
                  :rules="firstNameRules"
                  label="First Name"
                  required
                  dense
                  outlined
                  class="pr-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pl-1"
                  v-model="employee.last_name"
                  :rules="lastNameRules"
                  label="Last Name"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="employee.email"
                  label="Email"
                  :rules="emailRules"
                  required
                  class="pr-1"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                  class="pl-1"
                  v-model="employee.dialing_code"
                  :items="dial_dode_list"
                  label="Dialing code"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  class="pl-1"
                  
                  v-model="employee.phone"
                  label="Phone number"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="employee.company_admin == 'Yes'">
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="employee.company_name"
                  label="Company"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="employee.company_admin == 'Yes'">
              <v-col cols="12" sm="6">
                <v-text-field
                    class="pr-1"
                    v-model="employee.address"
                    label="Address 1"
                    outlined
                    dense
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  class="pl-1"
                  v-model="employee.country_id"
                  :items="country_list"
                  label="Country"
                  outlined
                  dense
                  @change="changeStates"
                >
                </v-select>
                
              </v-col>
            </v-row>

            <v-row no-gutters v-if="employee.company_admin == 'Yes'">
              <v-col cols="12" sm="6">
                <v-text-field
                    class="pr-1"
                    v-model="employee.suite_number"
                    label="Address 2"
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              
                <v-col cols="12" sm="2">
                  <v-select
                    class="pr-1 pl-1"
                    v-model="employee.state_id"
                    :items="state_list"
                    label="State"
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    class="pr-1 pl-1"
                    v-model="employee.zipcode"
                    label="Zip Code"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    class="pr-1 pl-1"
                    v-model="employee.city"
                    label="City"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="employee.company_admin == 'Yes'">
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-if="company_type == 'Small Enterprise'"
                  v-model="employee.company.org_type"
                  :items="small_org_type_list"
                  label="Organization Type"
                  outlined
                  dense
                >
                </v-select>
                <v-select
                  class="pr-1 pl-1"
                  v-else
                  v-model="employee.company.org_type"
                  :items="chapter_org_type_list"
                  label="Organization Type"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" v-if="company_type == 'Small Enterprise'">
                <v-select
                  class="pr-1 pl-1"
                  v-model="employee.company.revenue"
                  :items="revenue_list"
                  label="Revenue"
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" v-if="company_type != 'Small Enterprise'">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="employee.company.legal_status"
                  label="Legal Status"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="employee.company_admin == 'Yes' && company_type == 'Small Enterprise'">
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-model="employee.company.emp_count"
                  :items="emp_count_list"
                  label="No. of Employees"
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-model="employee.company.industry"
                  :items="industry_list"
                  label="Industry"
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="employee.company_admin == 'Yes' && company_type == 'Small Enterprise'">
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="employee.company.ein"
                  label="EIN Number(optional)"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" v-if="employee.company.industry == 'Other (Please enter into box)'">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="employee.company.other_industry"
                  label="Other Industry"
                  :rules="otherIndustryRules"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="employee.company_admin == 'Yes' && company_type != 'Small Enterprise'">
              <v-col cols="12" sm="6">
                <v-select
                  class="pr-1 pl-1"
                  v-model="employee.company.chapter_of_org"
                  :items="chapter_of_org_list"
                  label="Are you a chapter of larger organization"
                  outlined
                  dense
                  required
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" v-if="employee.company.chapter_of_org == 1">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="employee.company.parent_org"
                  label="Parent Organization"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-container fluid class="card-footer pl-0 pt-3">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validateProfileForm"
              >
                Update
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>
    <!--end::Employees-->

    <!-- start::password -->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Change Password</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
          <v-form ref="pform" v-model="valid_password" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="password"
                  label="Password"
                  outlined
                  type="password"
                  :rules="passwordRule"
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="confirm_password"
                  label="Confirm Password"
                  type="password"
                  :rules="confirmPasswordRule"
                  outlined
                  dense
                  required
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-container fluid class="card-footer pl-0 pt-3">
              <v-btn
                :disabled="!valid_password"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validatePasswordForm"
              >
                Update Password
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>
    <!-- end::password -->

    <!-- start::Bank details -->
    <div class="card card-custom gutter-b" v-if="employee.company_admin == 'Yes'">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Company bank details</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
          <v-form ref="bankform" v-model="valid_bank_details" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="bank_details.account_number"
                  label="Account number"
                  outlined
                  type="text"
                  :rules="account_number_rule"
                  dense
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="bank_details.routing_number"
                  label="Route number"
                  type="text"
                  :rules="route_number_rule"
                  outlined
                  dense
                  required
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-container fluid class="card-footer pl-0 pt-3">
              <v-btn
                :disabled="!valid_bank_details"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validateBankDetailsForm"
              >
                Update details
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>
    <!-- end::Bank details -->

    <!-- start::Stripe details -->
    <!-- <div class="card card-custom gutter-b" v-if="employee.company_admin == 'Yes' && (stripe_account_status == 'charges_disabled' || stripe_account_status == '')"> -->
    <div class="card card-custom gutter-b" v-if="employee.company_admin == 'Yes'">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Stripe details</h3>
          <div v-if="!(employee.company.stripe_account_id)">
          (Add Stripe details for enabling payout.)
          </div>
          <div v-if="!(stripe_details.charges_enabled) && employee.company.stripe_account_id">
          (Update Address, City, Zipcode, SSN Last 4 Digit values for enabling payout.)
          </div>
          <div v-if="stripe_details.status == 'unverified'">
          (Update Address, City, Zipcode, SSN Last 4 Digit values for enabling payout.)
          </div>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
          <v-form ref="stripeform" v-model="valid_stripe_details" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="stripe_details.account_number"
                  label="Account number"
                  outlined
                  type="text"
                  dense
                  autocomplete="off"
                  v-if="stripe_account_id"
                >
                </v-text-field>
                <v-text-field v-else
                  class="pr-1 pl-1"
                  v-model="stripe_details.account_number"
                  label="Account number"
                  outlined
                  type="text"
                  :rules="account_number_rule"
                  dense
                  autocomplete="off"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="stripe_details.routing_number"
                  label="Route number"
                  type="text"
                  :rules="route_number_rule"
                  outlined
                  dense
                  required
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="2">
                <v-select
                  class="pr-1 pl-1"
                  v-model="stripe_details.dialing_code"
                  :items="dial_dode_list"
                  label="Dialing code"
                  outlined
                  dense
                  disabled
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  class="pr-1 pl-1"
                  :rules="phoneRules"
                  v-model="stripe_details.company_phone"
                  label="Company Phone number"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                    class="pr-1 pl-1"
                    :rules="stateRules"
                    v-model="stripe_details.state"
                    :items="stripe_state_list"
                    label="State"
                    outlined
                    dense
                  >
                </v-select>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  :rules="cityRules"
                  v-model="stripe_details.city"
                  label="City"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  :rules="addressRules"
                  v-model="stripe_details.address1"
                  label="Address"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <v-text-field
                  class="pr-1 pl-1"
                  :rules="zipRules"
                  v-model="stripe_details.postal_code"
                  label="Zip Code"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  class="pr-1 pl-1"
                  :rules="ssnRules"
                  v-model="stripe_details.ssn_last_4"
                  label="SSN Last 4 Digit"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    label="Birth Date"
                    v-model="stripe_details.dob"
                    :rules="dateRule"
                    type="date"
                    outlined
                    dense
                  ></v-text-field>
              </v-col>
            </v-row>

             <v-row no-gutters>
              <v-col cols="12" sm="12">
                <v-text-field
                  class="pr-1 pl-1"
                  :rules="businessUrlRules"
                  v-model="stripe_details.business_url"
                  label="Business Url"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
              
            <v-container fluid class="card-footer pl-0 pt-3">
              <v-btn
                :disabled="!valid_stripe_details"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validateStripeDetailsForm"
              >
                Update details
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>
    <!-- end::Stripe details -->

    <!-- start::Quickbooks details -->
    <div class="card card-custom gutter-b" v-if="employee.company_admin == 'Yes' && showQuickbooksCard">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Quickbooks details</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example">
        
          <v-form ref="quickbooksform" v-model="valid_quickbooks_details" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="12" class="pr-1 pl-1">
                <v-btn
                color="success"
                class="mr-4"
                @click="getQuickbooksAuthURL"
              >
              Click here to get URL for getting Auth Code & RealmID
              </v-btn>

              </v-col>
              <v-col cols="12" sm="12" class="mt-5">
                  <v-textarea
                    class="pr-1 pl-1"
                    v-model="authURL"
                    label="Auth URL"
                    rows=3
                    dense
                    outlined
                    readonly
                  ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" class="mb-5 pr-1 pl-2">
                <span> <b>Note:</b> Please follow below steps for getting Auth Code & RealmID:</span>
                  <ol class="m-0 p-0 pl-5 pt-2 li-mb-5">
                    <li>
                    Please go to above url. Do login into your quickbooks account. 
                    </li>
                    <li>
                    Choose your app from "Select app" dropdown on that page. 
                    </li>
                    <li>
                    Choose Scopes (From "OAuth settings") on that page. 
                    </li>
                    <li>
                    Click on "Get authorization code" button on that page. 
                    </li>
                    <li>
                    You will get Authorization Code & Realm ID (From Get OAuth 2.0 token from auth code on that page.). Copy Authorization Code & Realm ID and paste them into below text-boxes.
                    </li>
                  </ol> 
              </v-col>
            </v-row>  

            <v-row no-gutters v-if="authURL != ''">
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="quickbooks_details.authCode"
                  label="Auth Code"
                  type="text"
                  :rules="authCode_rule"
                  outlined
                  dense
                  required
                  autocomplete="off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="pr-1 pl-1"
                  v-model="quickbooks_details.realmId"
                  label="RealmID"
                  type="text"
                  :rules="realmId_rule"
                  outlined
                  dense
                  required
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-container fluid class="card-footer pl-0 pt-3" v-if="authURL != ''">
              <v-btn
                :disabled="!valid_quickbooks_details"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validateQuickbooksDetailsForm"
              >
                Update details
              </v-btn>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>
    <!-- end::Quickbooks details -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { UPDATE_PASSWORD, UPDATE_BANK_DETAILS } from "@/core/services/store/auth.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { GET_COUNTRIES, GET_STATES } from "@/core/services/store/user.module";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { format, parseISO } from "date-fns";

export default {
  name: "profileEdit",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      stripe_account_id:"",
      stripe_account_status:"",
      employee: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        designation: "",
        user_type: 3,
        company: {},
        avatar: [],
        country_id: "",
        state_id: "",
        company_admin:"",
        dialing_code:""
      },
      bank_details: {
        routing_number: "",
        account_number: ""
      },
      stripe_details: {
        routing_number: "",
        account_number: "",
        dialing_code:"+1",
        company_phone:"",
        state:"",
        city:"",
        address1:"",
        postal_code:"",
        ssn_last_4:"",
        business_url:"",
        dob: format(
              parseISO(new Date().toISOString()),
              "yyyy-MM-dd"
            ),
        charges_enabled:false,
        status : ""    
      },
      quickbooks_details: {
        authCode: "",
        realmId: ""
      },
      authURL: "",
      showQuickbooksCard: true,
      company_type: "",
      password: "",
      confirm_password: "",
      url: "",
      profile_pic: "",
      countries: [],
      country_list: [],
      dial_dode_list: ["+1","+91","+44"],
      emp_count_list: ["<25","26-50","51-100","101-250","250+"],
      revenue_list: [],
      chapter_of_org_list:[],
      industry_list:[
                      "Agriculture, Forestry, Fishing and Hunting",
                      "Arts, Entertainment, and Recreation",
                      "Broadcasting",
                      "Computer and Electronics Manufacturing",
                      "Daycare and Other Childcare",
                      "Education - College, University, Adult, and Professional Education",
                      "Education - Primary/Secondary (K-12)",
                      "Education - Other",
                      "Energy, Utilities",
                      "Finance and Insurance",
                      "Government and Public Administration",
                      "Healthcare",
                      "Hotel and Lodging Services",
                      "Industrials (Manufacturing, Construction, Mining)",
                      "Industrials - Other",
                      "Law and Legal Services",
                      "Military",
                      "Professional Services (including Consulting)",
                      "Publishing, Media",
                      "Real Estate, Rental and Leasing",
                      "Religious Services",
                      "Restaurants and Dining",
                      "Retail",
                      "Scientific and Technical Services",
                      "Social Services and Social Assistance",
                      "Software and Technology",
                      "Telecommunications",
                      "Transportation, Logistics, and Warehousing",
                      "Wholesale",
                      "Other (Please enter into box)"
                    ],
      states: [],
      state_list: [],
      stripe_state_list :[],
      org_types: [],
      chapter_org_type_list: [],
      small_org_type_list: [],
      org_type_list: [],
      all_org_types: [],
      employee_image: {},
      valid: true,
      valid_password: true,
      valid_bank_details: true,
      valid_stripe_details: true,
      valid_quickbooks_details: true,
      account_number_rule: [
        v => !!v || "Account number is required"
      ],
      route_number_rule: [
        v => !!v || "Route number is required"
      ],
      passwordRule: [
        v => !!v || "Password is required",
        v =>
          (v && v.length >= 6) ||
          "Password must be greater than or equal 6 characters"
      ],
      confirmPasswordRule: [
        v =>
          (v && v == this.password) ||
          "Password and Confirm Password is not same"
      ],
      firstNameRules: [
        v => !!v || "First Name is required",
        v =>
          (v && v.length <= 25) || "First Name must be less than 25 characters"
      ],
      lastNameRules: [
        v => !!v || "Last Name is required",
        v =>
          (v && v.length <= 25) || "Last Name must be less than 25 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(v) ||
          "E-mail must be valid"
      ],
      otherIndustryRules: [
        v => !!v || "Other Industry is required"
      ],
      phoneRules: [
        v => !!v || "Phone number is required",
        v =>
          (v && v.length <= 15) ||
          "Phone number must be less than 15 characters",
        v =>
          (v && v.length >= 6) ||
          "Phone number must be greater than 5 characters"
      ],
      stateRules: [
        v => !!v || "State is required"
      ],
      cityRules: [
        v => !!v || "City is required"
      ],
      addressRules: [
        v => !!v || "Address is required"
      ],
      ssnRules: [
        v => !!v || "SSN Last 4 digit is required"
      ],
      dateRule: [
        v => {
          if (!v.trim()) return true;
          var d1 = new Date();
          var d2 = new Date(v);
          if (d2 <= d1) return true;
          return "Please add past date";
        }
      ],
      businessUrlRules: [
        v => !!v || "Business Url is required"
      ],
      zipRules: [
        v => !!v || "Zip Code is required",
        v => (v && v.length >= 5) || "Zip Code must be greater than 5 character"
      ],
      realmId_rule: [
        v => !!v || "RealmId is required"
      ],
      authCode_rule: [
        v => !!v || "Auth Code is required"
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters([
      "currentUserPersonalInfo",
      "currentUser",
      "getCompany",
      "getCountries",
      "getStates"
      // "getOrganizatonTypes"
    ])
  },
  watch: {
    currentUserPersonalInfo(user) {
      this.employee.first_name = user.name;
      this.employee.last_name = user.surname;
      this.employee.email = user.email;
      this.employee.phone = user.phone;
      this.employee.company_admin = user.company_admin;
      this.employee.dialing_code = user.dialing_code;
    },
    currentUser(userObj) {
      this.employee.suite_number = userObj.user.suite_number;
      this.employee.address = userObj.user.address;
      this.employee.zipcode = userObj.user.zipcode;
      this.employee.city = userObj.user.city;
    },
    getCompany(companyObj) {
      this.employee.company = companyObj;
      this.employee.company_name = companyObj.name;
    }
  },
  mounted() {
    let context = this;
    context.getStripeStates();
    context.chapter_of_org_list.push({ text: "Yes", value: 1 },
                                     { text: "No", value: 0 } 
                                );                         
  

    context.getBankDetails();
    this.$store.dispatch(GET_COUNTRIES);
    this.$store.dispatch(GET_STATES);
    if (context.getStates) {
      context.states = context.getStates;
      context.states.forEach(state => {
        context.state_list.push({ text: state.name, value: state.id });
      });
    } else {
      axios({
        method: "post",
        url: "get-states",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.states) {
            context.states = result.data.states;
            result.data.states.forEach(state => {
              context.state_list.push({ text: state.name, value: state.id });
            });
          } else {
            Swal.fire("Error", "States not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "States not found!", "error");
        }
      );
    }

    if (context.getCountries) {
      context.countries = context.getCountries;
      context.countries.forEach(country => {
        context.country_list.push(country.name);
      });
    } else {
      axios({
        method: "post",
        url: "get-countries",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.countries) {
            result.data.countries.forEach(country => {
              context.country_list.push({
                text: country.name,
                value: country.id
              });
            });
          } else {
            Swal.fire("Error", "Countries not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "Countries not found!", "error");
        }
      );
    }

    if (context.getOrganizatonTypes) {
      context.org_types = context.getOrganizatonTypes;
      context.org_types.forEach(org_type => {
        context.org_type_list.push(org_type.type);
      });
    } else {
      this.getOrganizatonTypesObj();
    }

    axios({
        method: "post",
        url: "get-revenue-levels",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.revenue_list) {
            result.data.revenue_list.forEach(revenue_list => {
              context.revenue_list.push({
                text: revenue_list.min_revenue+' - '+revenue_list.max_revenue,
                value: revenue_list.min_revenue+'-'+revenue_list.max_revenue,
              });
            });
          } else {
            Swal.fire("Error", "Countries not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "Countries not found!", "error");
        }
      );

    // if (context.getCompany) {
    //   context.employee.company = context.getCompany;
    //   context.employee.company_name = context.getCompany.name;
    // } else {
    //If company data not received from user.module store
    context.getCompanyObj();
    // }

    if (context.currentUserPersonalInfo) {
      context.employee.first_name = context.currentUserPersonalInfo.name;
      context.employee.last_name = context.currentUserPersonalInfo.surname;
      context.employee.email = context.currentUserPersonalInfo.email;
      context.employee.phone = context.currentUserPersonalInfo.phone;
      context.employee.company_admin = context.currentUserPersonalInfo.company_admin;
      context.employee.dialing_code = context.currentUserPersonalInfo.dialing_code;
    }

    if (context.currentUser && context.currentUser.user) {

      context.employee.suite_number = context.currentUser.user.suite_number;
      context.employee.address = context.currentUser.user.address;
      context.employee.zipcode = context.currentUser.user.zipcode;
      context.employee.city = context.currentUser.user.city;
      context.employee.country_id = context.currentUser.user.country_id;
      context.employee.state_id = context.currentUser.user.state_id;
    } else {
      context.getCurrentUser();
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);

    const profile_form = KTUtil.getById("kt_profile_form");

    this.fv = formValidation(profile_form, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getCurrentUser() {
      let context = this;
      axios({
        method: "get",
        url: "get-current-user",
        baseURL: process.env.VUE_APP_ACAUTH_URL
      }).then(
        function(result) {
          if (result.data.user) {
            context.employee.suite_number = result.data.user.suite_number;
            context.employee.address = result.data.user.address;
            context.employee.zipcode = result.data.user.zipcode;
            context.employee.country_id = result.data.user.country_id;
            context.employee.state_id = result.data.user.state_id;
            context.employee.city = result.data.user.city;
          } else {
            // context.getCurrentUser();
          }
        },
        function() {
          context.getCurrentUser();
        }
      );
    },
    getOrganizatonTypesObj() {
      let context = this;
      axios({
        method: "post",
        url: "get-organization-types",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.all_org_types = result.data.organization_types;
          if (!result.data.organization_types) {
            context.getOrganizatonTypesObj();
          }
        },
        function() {
          context.getOrganizatonTypesObj();
        }
      );
    },
    getCompanyObj() {
      let context = this;
      axios({
        method: "post",
        url: "get-company",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.company) {
            context.company_type = result.data.company.company_type;

            result.data.company.org_type = result.data.company.org_type;
            context.employee.company = result.data.company;
            context.employee.company.industry =
              context.employee.company.industry != "null"
                ? context.employee.company.industry
                : "";
            context.employee.company.sic_naic =
              context.employee.company.sic_naic != "null"
                ? context.employee.company.sic_naic
                : "";
            context.employee.company.ein =
              context.employee.company.ein != "null"
                ? context.employee.company.ein
                : "";
            context.employee.company.legal_status =
              context.employee.company.legal_status != "null"
                ? context.employee.company.legal_status
                : "";    
            context.employee.company.parent_org =
              context.employee.company.parent_org != "null"
                ? context.employee.company.parent_org
                : "";    
            context.employee.company_name = result.data.company.name;
            context.stripe_account_status = result.data.company.stripe_account_status ? result.data.company.stripe_account_status : '';

            if(result.data.company.stripe_account_id){
              context.stripe_details = result.data.stripe_details;
              context.stripe_account_id = result.data.company.stripe_account_id;
            }

            if(result.data.company.qb_realm_id && result.data.company.qb_refresh_token){
              context.showQuickbooksCard = false;
            }
          } else {
            Swal.fire(result.data.error, "", "error");
          }
          context.all_org_types.forEach(org_type => {
            if (org_type.company_type == "Small Enterprise") {              
              context.small_org_type_list.push({ text: org_type.type, value: org_type.type });
            } else if (context.company_type == "Chapter Organization") {
              context.chapter_org_type_list.push({ text: org_type.type, value: org_type.type });
            }
          });
        },
        function() {
          context.getCompanyObj();
        }
      );
    },
    onFileChange(e) {
      if (e) {
        this.currentUserPersonalInfo.photo = URL.createObjectURL(e); // jQuery selector
      }
    },
    changeStates() {
      let context = this;
      context.state_list = [];
      context.states.forEach(state => {
        if (state.country_id == context.employee.country_id) {
          context.state_list.push({ text: state.name, value: state.id });
        }
      });
    },
    validateProfileForm() {
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.updateProfile();
      } else {
        this.snackbar = true;
      }
    },
    getBankDetails() {
      var context = this;
      axios({
        method: "get",
        url: "bank-details",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        headers: {
          "Content-type": "application/x-www-form-urlencoded"
        }
      }).then(
        function(result) {
          if(result.data.bank_details) {
            context.bank_details = result.data.bank_details;
          }
        });
    },
    getStripeStates() {
      var context = this;
      axios({
        method: "get",
        url: "get-states?country_id=1",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.states) {
            context.stripe_state_list = [];
            result.data.states.forEach(state => {
              context.stripe_state_list.push({ text: state.name, value: state.code });
            });
          } else {
            Swal.fire("Error", "States not found!", "error");
          }
        },
        function() {
          Swal.fire("Error", "States not found!", "error");
        });
    },
    validateBankDetailsForm() {
       this.fv.on("core.bankform.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.bankform.validate()) {
        this.updateBankDetails();
      } else {
        this.snackbar = true;
      }
    },
    getQuickbooksAuthURL(){
      let context = this;
      axios({
        method: "get",
        url: "get-quickbooks-auth-url",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if(result.data.status == 0){
            Swal.fire("Error", result.data.message, "error");
          }else{
            Swal.fire("Success", result.data.message, "success");
            context.authURL = result.data.authURL;
          }
        },
        function() {
          Swal.fire("Error", "Error in getting Quickbooks Auth URL.", "error");
        }
      );
    },
    validateQuickbooksDetailsForm(){
      this.fv.on("core.quickbooksform.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.quickbooksform.validate()) {
        this.updateQuickbooksDetails();
      } else {
        this.snackbar = true;
      }
    },
    validateStripeDetailsForm() {
       this.fv.on("core.stripeform.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.stripeform.validate()) {
        this.updateStripeDetails();
      } else {
        this.snackbar = true;
      }
    },
    validatePasswordForm() {
      this.fv.on("core.pform.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.pform.validate()) {
        this.updatePassword();
      } else {
        this.snackbar = true;
      }
    },
    updateBankDetails() {
      let context = this;
      this.$store
        .dispatch(UPDATE_BANK_DETAILS, context.bank_details);
    },
    updateStripeDetails() {
      let context = this;
      axios({
        method: "post",
        url: "create-stripe-account",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: context.stripe_details
      }).then(
        function(result) {
          if(result.data.status == 0){
            Swal.fire("Error", result.data.message, "error");
          }else{
            Swal.fire("Success", result.data.message, "success");
            context.charges_enabled = true;
          }
        },
        function() {
          Swal.fire("Error", "Error in stripe detail update", "error");
        }
      );
    },
    updateQuickbooksDetails() {
      let context = this;
      axios({
        method: "post",
        url: "save-quickbooks-token",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: context.quickbooks_details
      }).then(
        function(result) {
          if(result.data.status == 0){
            Swal.fire("Error", result.data.message, "error");
          }else{
            Swal.fire("Success", result.data.message, "success");
            context.showQuickbooksCard = false;
          }
        },
        function() {
          Swal.fire("Error", "Error in quickbooks details update.", "error");
        }
      );
    },
    updatePassword() {
      let context = this;
      this.$store
        .dispatch(UPDATE_PASSWORD, context.password)
        .then(() => this.$router.push({ name: "dashboard" }));
    },
    updateProfile() {
      var context = this;
      var formData = new FormData();
      //User's Data
      formData.append("avatar", context.employee.avatar);
      formData.append("first_name", context.employee.first_name);
      formData.append("last_name", context.employee.last_name);
      formData.append("email", context.employee.email);
      formData.append("dialing_code", context.employee.dialing_code);
      formData.append("phone", context.employee.phone);
      formData.append("suite_number", context.employee.suite_number);
      formData.append("address", context.employee.address);
      formData.append("zipcode", context.employee.zipcode);
      formData.append("country_id", context.employee.country_id);
      formData.append("state_id", context.employee.state_id);
      formData.append("city", context.employee.city);

      //Company's Data
      formData.append("company_name", context.employee.company_name);
      formData.append("emp_count", context.employee.company.emp_count);
      formData.append("org_type", context.employee.company.org_type);
      formData.append("industry", context.employee.company.industry);

      if(context.employee.company.industry == 'Other (Please enter into box)'){
        formData.append("other_industry", context.employee.company.other_industry);
      }

      formData.append("revenue", context.employee.company.revenue);
      formData.append("sic_naic", context.employee.company.sic_naic);
      formData.append("ein", context.employee.company.ein);
      formData.append("legal_status", context.employee.company.legal_status);
      formData.append("chapter_of_org", context.employee.company.chapter_of_org);
      formData.append("parent_org", context.employee.company.parent_org);

      axios({
        method: "post",
        url: "profile-update",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: formData,
        headers: {
          "Content-type": "application/x-www-form-urlencoded"
        }
      }).then(
        function(result) {
          if(result.data.status == 0){
            Swal.fire("Error", result.data.message, "error");
          }else{
            Swal.fire("Success", result.data.message, "success");
          }
        },
        function() {
          Swal.fire("Error", "Error in profile update", "error");
        }
      );
    },
    initPasswordForm() {
      let context = this;
      context.password = "";
      context.confirm = "";
    }
  }
};
</script>
